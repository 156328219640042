@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'), url(../fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'), url(../fonts/Montserrat-Regular.ttf) format('truetype');
}

* {
  box-sizing: border-box;
}

h1 {
  font-family: MontserratBold, sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin: 0 0 33px 0;
  text-align: center;
}

h3 {
  font-family: MontserratBold, sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 0 0;
}

div, p, a, span {
  font-family: MontserratRegular, sans-serif;
  font-size: 16px;
  white-space: pre-line;
  margin: 0;
}

.default-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-field {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-label {
  font-size: 17px;
  & span.required {
    color: red;
  }
}

.form-error {
  font-size: 14px;
  color: red;
}
