.uiSuccess {
  width: 40px;
  height: 40px;
  display: flex;
}

.uiSuccess {
  & .circle {
    stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
    stroke-dashoffset: 260.75219024795285px;
    transform: rotate(220deg);
    transform-origin: center center;
    stroke-linecap: round;
    animation: ani-success-circle 1s ease-in both;
  }
  & .path {
    stroke-dasharray: 60px 64px;
    stroke-dashoffset: 62px;
    stroke-linecap: round;
    animation: ani-success-path .4s 1s ease-in both;
  }
}

@keyframes ani-success-circle {
  to{stroke-dashoffset: 782.2565707438586px;}
}

@keyframes ani-success-path {
  0% {stroke-dashoffset: 62px;}
  65% {stroke-dashoffset: -5px;}
  84%{stroke-dashoffset: 4px;}
  100%{stroke-dashoffset: -2px;}
}
