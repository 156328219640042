@import "../../../assets/styles/variables";

.logo {
  margin-top: 50px;
}

.wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &Root {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  &Main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 10px 0 50px 0;
  }
}

.header {
  font-size: 24px;
  color: $primary;
}