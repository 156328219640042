.wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.logo {
  width: 44px;
  height: 65px;
  &:hover {
    cursor: pointer;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  background-color: #73C120;
  border: none;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  & img {
    width: 33px;
    height: 33px;
  }
}

.title {
  font-size: 26px;
}