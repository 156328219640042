.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  p {
    font-size: 18px;
  }
}

.wrapperButtons {
  display: flex;
  align-items: center;
  gap: 10px;
}