@import '../../assets/styles/variables';

.root {
  font-family: TTNormRegular, sans-serif;
  font-size: 18px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid transparent;
  width: 100%;
  height: 45px;
  display: flex;
  gap: 5px;
  overflow: hidden;
  &.focused {
    outline: none;
    border: 1px solid #73C120;
  }
  &.open {
    border-radius: 10px 10px 0 0;
  }
  &.error {
    border: 1px solid red;
  }
  &.readOnly {
    border: 1px solid rgba(grey, 0.4);
    background-color: rgba(grey, 0.2);
  }
}

.input {
  font-family: inherit;
  background: transparent;
  border: none;
  font-size: inherit;
  border-radius: inherit;
  padding: 10px 10px;
  outline: 0;
  width: 100%;
  &_root {
    background: transparent;
    border-radius: inherit;
    font-size: inherit;
    flex: 1 0 auto;
  }
}


.indicator {
  outline: 0;
  box-shadow: none;
  border: 0;
  border-radius: 4px;
  background-color: transparent;
  align-self: center;
  padding: 0 2px;
  margin-right: 4px;
  &:hover {
    background-color: rgba($primary, 0.2);
    cursor: pointer;
  }
  &.indicator_clear > svg {
    transform: translateY(2px) scale(0.9);
  }
  &.indicator_popup {
    & > svg {
      transform: translateY(2px);
    }
    &.popupOpen > svg {
      transform: translateY(2px) rotate(180deg);
    }
  }
}

.popper {
  position: relative !important;
  z-index: 1001;
  width: 100%;
  transform: translate(0, 0) !important;
}

.listBox {
  font-family: TTNormRegular, sans-serif;
  font-size: 20px;
  padding: 0;
  margin: 0;
  width: 100%;
  border-radius: 0 0 10px 10px;
  overflow: auto;
  outline: 0;
  max-height: 160px;
  z-index: 1;
  position: absolute;
  background: #ffffff;
  border: 1px solid $primary;
  color: black;
  box-shadow: 0 4px 6px rgba(0,0,0, 0.05);
}

.option {
  list-style: none;
  padding: 8px;
  cursor: default;
  border-bottom: 1px solid $primary;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &[aria-selected=true] {
    background-color: rgba($primary, 0.2);
    color: black;
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background-color: rgba($primary, 0.2);
    color: black;
  }

  &.Mui-focusVisible {
    box-shadow: 0 0 0 3px rgba($primary, 0.2);
  }

  &[aria-selected=true].Mui-focused,
  &[aria-selected=true].Mui-focusVisible {
    background-color: rgba($primary, 0.2);
    color: black;
  }
}

.noOptions {
  list-style: none;
  padding: 8px;
  cursor: default;
}