@import '../../assets/styles/variables';

.greenHeader {
  background-color: $primary !important;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}