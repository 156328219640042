@import "../../assets/styles/variables";

.button {
  font-size: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary;
  height: 45px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  border: none;
  &:hover {
    cursor: pointer;
  }
  &.disable {
    background: grey;
    opacity: 0.8;
    &:hover {
      cursor: default;
    }
  }
}