@import '../../../assets/styles/variables';

.card {
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
}

.title {
  font-size: 18px;
}

.unsync {
  background-color: $primary;
  color: white;
}

.menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}