.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  padding: 0 10px;
}

.content {
  position: relative;
  padding: 20px 10px;
  border-radius: 10px;
  background-color: white;
  min-height: 150px;
  width: min(100%, 450px);
  z-index: 11;
}

.close {
  position: absolute !important;
  top: 6px;
  right: 6px;
}