.input {
  font-family: TTNormRegular, sans-serif;
  display: block;
  font-size: 18px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 1px solid transparent;
  width: 100%;
  height: 45px;
  padding: 0 10px;
  &:focus {
    outline: none;
    border: 1px solid #73C120;
  }
}