@import '../../../assets/styles/variables';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & h2 {
    font-family: TTNormBold, sans-serif;
    font-size: 20px;
    color: black;
  }
  p {
    color: black;
  }
  &Buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.btn {
  &.resolve {
    background-color: $secondary;
  }
}