.wrapper {
  &Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-column: 1 / 2;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-column: 2 / 2;
}

.note {
  width: 100%;
  margin-top: 10px;
}