.wrap {
  position: relative;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: min(100%, 450px);
  min-height: 100vh;
  overflow-x: hidden;
}

